import * as THREE from 'three'
import Experience from '../Experience.js'
import Environment from './Enviroment.js'
import Floor from './Floor.js'
import Fox from './Fox.js'

export default class World {

    constructor(){

        this.experience = new Experience()
        this.debug = this.experience.debug
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        

        // Debug 
        if(this.debug.active){
            this.debugFolder = this.debug.gui.addFolder('World')
        }

        // // Test Mesh
        // const testMesh = new THREE.Mesh(
        //     new THREE.BoxGeometry(1, 1, 1),
        //     new THREE.MeshStandardMaterial({
        //         // wireframe: true
        //     })
        // )
        // this.scene.add(testMesh)

        //
        this.resources.on('ready', () => {
            
            // Setup
            this.floor = new Floor()
            this.fox = new Fox()
            this.enviroment = new Environment()

            // Fog
            this.scene.fog = new THREE.FogExp2(0xffffff, 0.0219)
            
            
            if(this.debug.active){
            this.debugFolder.addColor(this.scene.fog, 'color').name('fogColor')
            this.debugFolder.add(this.scene.fog, 'density').min(0).max(0.2).step(0.0001).name('fogDensity')
            }
        })

    }

    update(){
        if(this.fox){
            this.fox.update()
        }
       
    }
}