import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Fox {

    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active){
            this.debugFolder = this.debug.gui.addFolder('Fox')
        }

        // Parameter 
        this.parameter = {
            speed: 0.00045,
            
        }

        // Setup
        this.resource = this.resources.items.foxModel
        
        this.setModel()
        this.setAnimation()
    }

    setModel(){

        this.model = this.resource.scene
        this.model.scale.set(0.02, 0.02, 0.02)
        this.scene.add(this.model)

        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh){
                child.castShadow = true
            }
        })
    }

    setAnimation(){
        
        this.animation = {}
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        // this.animation.action = this.animation.mixer.clipAction(this.resource.animations[0])
        // this.animation.action.play()

        this.animation.actions = {}

        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0])
        this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])

        this.animation.actions.current = this.animation.actions.walking
        this.animation.actions.current.play()

        this.animation.play = (name) => {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 1)

            this.animation.actions.current = newAction
        }

        // Debug
        if(this.debug.active){

            const debugObject = {
                playIdle: () => { 
                    this.parameter.speed = 0,
                    this.animation.play('idle')
                },
                playWalking: () => { 
                    this.parameter.speed = 0.00045,
                    this.animation.play('walking') 
                },
                playRunning: () => { 
                    this.parameter.speed = 0.00082,
                    this.animation.play('running') 

                }
            }

            this.debugFolder.add(debugObject, 'playIdle')
            this.debugFolder.add(debugObject, 'playWalking')
            this.debugFolder.add(debugObject, 'playRunning')
            this.debugFolder.add(this.parameter, 'speed').min(0).max(0.005).step(0.00001)
        }
    }

    update(){
        this.animation.mixer.update(this.time.delta * 0.001)

        // console.log(this.model.position)

        this.model.position.x = - (Math.sin(this.time.elapsed * this.parameter.speed) * 4);
        this.model.position.z = - (Math.cos(this.time.elapsed * this.parameter.speed) * 4);
        this.model.rotation.y = (Math.PI * 1.5) + Math.PI * 0.00005 + this.time.elapsed * this.parameter.speed;

    }


}