import * as THREE from 'three'
import Experience from "../Experience.js"
import { normalMap } from 'three/examples/jsm/nodes/Nodes.js'

export default class Floor {

    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setGeometry()
        this.setTextures()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry(){

        this.geometry = new THREE.CircleGeometry(10, 64)

    }

    setTextures(){

        this.textures = {}

        this.textures.color = this.resources.items.dirtColorTexture
        this.textures.color.colorSpace = THREE.SRGBColorSpace
        this.textures.color.repeat.set(1.5, 1.5)
        this.textures.color.wrapS = THREE.RepeatWrapping
        this.textures.color.wrapT = THREE.RepeatWrapping

        this.textures.normal = this.resources.items.dirtNormalTexture
        this.textures.normal.repeat.set(1.5, 1.5)
        this.textures.normal.wrapS = THREE.RepeatWrapping
        this.textures.normal.wrapT = THREE.RepeatWrapping
        this.textures.transparent = true
        // this.textures.alphaMap = this.resources.items.dirtAlphaTexture
        

    }

    setMaterial(){

        this.material = new THREE.MeshStandardMaterial({
            map: this.textures.color,
            normalMap: this.textures.normal,
            transparent: true,
            alphaMap: this.resources.items.dirtAlphaTexture
        })

    }

    setMesh(){

        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.rotation.x = - Math.PI * 0.5
        this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }

}