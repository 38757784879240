import * as THREE from 'three'
import Experience from "./Experience.js"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera {

    constructor(){
        
        this.experience = new Experience()
        this.debug = this.experience.debug
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        // Debug
        if(this.debug.active){
            this.debugFolder = this.debug.gui.addFolder('Camera')
        }
        
        this.setInstance()
        this.setOrbitControls()

    }

    setInstance(){
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width / this.sizes.height,
            0.1,
            100
        )
        this.instance.position.set(6, 4, 8)
        this.scene.add(this.instance)
    }

    setOrbitControls(){



        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.minDistance = 4
        this.controls.maxDistance = 14
        
        this.controls.minPolarAngle = 0.691
        this.controls.maxPolarAngle = 1.349


        //Debug 
        if(this.debug.active){
            const orbitDebug = this.debugFolder.addFolder('Oribit')

            orbitDebug.add(this.controls, 'minDistance').min(0).max(20).step(1).name('minDistance').onChange()
            orbitDebug.add(this.controls, 'maxDistance').min(0).max(20).step(1).name('minDistance').onChange()
            orbitDebug.add(this.controls, 'minPolarAngle').min(- (Math.PI * 2)).max( Math.PI * 2).step(0.001).onChange()
            orbitDebug.add(this.controls, 'maxPolarAngle').min(- (Math.PI * 2)).max( Math.PI * 2).step(0.001).onChange()
        }
    }

    resize(){
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update(){
        this.controls.update()
       
    }
}