import * as THREE from 'three'
import Experience from "./Experience.js";

export default class Renderer {

    constructor(){
        this.experience = new Experience()
        this.debug = this.experience.debug
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        
        // Debug 
        if(this.debug.active){
            this.debugFolder = this.debug.gui.addFolder('Renderer')
        }

        this.parameter = {
            color: new THREE.Color('#211d20'),
            
        }


        this.setInstance()
    }

    setInstance(){
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        })
        this.instance.toneMapping = THREE.CineonToneMapping
        this.instance.toneMappingExposure = 1.352
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor('#211d20')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))

        if(this.debug.active){
        this.debugFolder.add(this.instance, 'toneMappingExposure').min(0).max(4).step(0.0001)
        this.debugFolder.addColor(this.parameter, 'color').onChange(() => {this.instance.setClearColor(this.parameter.color)})
        }
    }

    resize(){
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    }

    update(){
        this.instance.render(this.scene, this.camera.instance)
    }
}